<script>
  import BaseIcon from '@/components/base/BaseIcon'

  /**
   * A clickable icon (button or link) with hover styles and customizable padding to expand the focusable & clickable area.
   */
  export default {
    extends: BaseIcon,
    props: {
      /** The type of component this is. When left `undefined`, the type is determined automatically based on the presence of link props (`to` or `href`). */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['button', 'link'].includes(value),
      },
      /** The size of the icon, in Tailwind units. */
      size: {
        type: Number,
        default: 5,
      },
      /** The padding around the icon, in Tailwind units. */
      padding: {
        type: Number,
        default: 1,
      },
      /** The color of the icon. Set to `null` if you want to specify custom color classes on the component. */
      color: {
        type: String,
        default: 'gray',
        validator: (value) => ['gray', 'black'].includes(value),
      },
    },
    computed: {
      totalSizeStyle() {
        if (!this.size) {
          return {}
        }
        return {
          width: this.$twToRem(this.size + 2 * this.padding),
          height: this.$twToRem(this.size + 2 * this.padding),
        }
      },
      colorClasses() {
        if (this.finalDisabled) {
          return 'text-gray text-opacity-50'
        }
        switch (this.color) {
          case 'gray':
            return 'text-gray hover:text-gray-darker active:text-gray-darkest'
          case 'black':
            return 'text-black hover:text-gray-darker active:text-gray-darkest'
          default:
            return ''
        }
      },
      propsToPassDown() {
        const { size, padding, color, ...propsToPassDown } = this.$props
        return propsToPassDown
      },
    },
  }
</script>

<template>
  <BaseIcon
    :class="`flex justify-center items-center rounded-sm ${colorClasses}`"
    :style="totalSizeStyle"
    v-bind="{ ...propsToPassDown, ...$attrs }"
    v-on="$listeners"
  >
    <span class="static" :style="sizeStyle">
      <!-- @slot The icon. Should contain an SVG icon component. -->
      <slot />
    </span>
  </BaseIcon>
</template>
